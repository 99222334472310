<template>
	<!-- 确定订单 -->
	<div class="page-all">
		<div class="page-title">请核对订单信息</div>
		<div class="page-content">
			<!-- 用户信息 -->
			<div class="contentTitle">用户信息</div>
			<div class="contentText">购买账号：{{resData.userinfo.nickname}}<span style="color: #999999;">（{{whichlogin}}）</span></div>
			<div class="contentText">手机号码：{{resData.userinfo.mobile}}</div>
			<!-- 课程信息 -->
			<div class="contentTitle">课程信息</div>
			<div v-for="(item,index) in resData.order">
				<div class="organizeName1">{{item.school_name}}</div>
				<div class="order">
					<div class="orderItem" v-for="(i,index) in item.goods">
						<div class="line" v-if="index!=0"></div>
						<img :src="i.goods_img" class="orderIcon">
						<div class="orderIntro">{{i.goods_name}}</div>
						<div class="orderPrice">￥{{i.goods_price}}</div>
					</div>
				</div>
				<div class="orderTotalBg">
					<div class="orderTotal">
						<span>机构优惠：</span>
						<el-select v-model="coupon_ids.id" placeholder="请选择优惠券"  @change="chooseCoupon()">
							<el-option v-for="(j,index) in item.coupon" :key="j.id" :label="j.coupon_name" :value="j.id"></el-option>
						</el-select>
						<span v-if="clickIn==false">小计：{{item.price}}</span>
						<span v-else="clickIn==true">小计：{{couponData.total_money}}</span>
					</div>
				</div>
			</div>
			<!-- 支付方式 -->
			<div class="contentTitle">支付方式</div>
			<div :class="['wxPay',choosePay==1?'wxPayIn':'']" @click="toChoosePay()">
				<img src="../../../../assets/img/organize/wx.png" class="wxIcon">
				微信
				<img src="../../../../assets/img/organize/ok.png" class="okBg" v-show="choosePay==1">
			</div>
			<div :class="['zfbPay',choosePay==2?'zfbPayIn':'']" @click="toChoosePay1()">
				<img src="../../../../assets/img/organize/zfb.png" class="wxIcon">
				支付宝
				<img src="../../../../assets/img/organize/ok.png" class="okBg1" v-show="choosePay==2">
			</div>
			<!-- 优惠券 -->
			<div class="contentTitle">优惠券</div>
			<div class="contentForm">
				<span>平台优惠：</span>
				<el-select v-model="pt_id" @change="chooseCoupon()">
					<el-option v-for="(item,index) in resData.pt_coupon" :key="item.id" :label="item.coupon_name" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="contentForm1">
				<span>优惠码：</span>
				<el-input v-model="input" placeholder="请输入兑换码" class="handleInput"></el-input>
				<div class="exchangeBtn">兑换</div>
			</div>
			<div class="line"></div>
			<!-- 结算 -->
			<div class="page-total">
				<div class="orderTotal">
					<div class="totalItem">
						<span>总金额：</span>
						<span>￥{{resData.total.total_money}}</span>
					</div>
					<div class="totalItem">
						<span>优惠券：</span>
						<span v-if="clickIn==false">-￥{{resData.total.coupon_money}}</span>
						<span v-else="clickIn==true">-￥{{couponData.coupon_money}}</span>
					</div>
					<div class="totalItem">
						<span>实付金额：</span>
						<span v-if="clickIn==false">￥{{resData.total.money}}</span>
						<span v-else="clickIn==true">￥{{couponData.total_money}}</span>
					</div>
					<div class="submitBtn" @click="toNext()">提交订单</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// 机构列表
				organizeData: [{}, {}],
				// 订单列表
				orderList: [{}, {}],
				// 下拉框
				options: [{
					value: '选项1',
					label: '满199减20'
				}, {
					value: '选项2',
					label: '满299减40',
					disabled: true
				}, {
					value: '选项3',
					label: '满399减70',
					disabled: true
				}, {
					value: '选项4',
					label: '不使用优惠券'
				}, ],
				value: '',
				// 支付方式(微信支付1 支付宝支付2)
				choosePay: 1,
				// 接收到的id
				resId: 5,
				resData: {},
				// 优惠券数据
				couponData: {},
				coupon_ids: {
					school_id: '',
					id: 0,
				},
				// 平台优惠券id
				pt_id: 0,
				// 小计
				money: '',
				// 是否点击优惠券
				clickIn: false,
				// 订单编号
				orderData: {},
				whichlogin:"账号密码"
			};
		},
		watch: {
			'$route': function() {
				this.getdata()
			},
		},
		created() {
			this.getdata()
		},
		methods: {
			getdata(){
				this.resId = this.$route.query.id
				this.resData = this.$route.query.data
				this.whichlogin = localStorage.getItem("whichlogin")
				// 机构优惠券id
				for(var i=0; i<this.resData.order.length; i++){
					this.coupon_ids.school_id = this.resData.order[i].school_id
					for(var j=this.resData.order[i].coupon.length; j<0; j--){
						this.coupon_ids.id = this.resData.order[i].coupon[j].id
					}
				}
				// 平台优惠券id
				for(var i=this.resData.pt_coupon.length; i>0; i--){
						this.pt_id = this.resData.pt_coupon[i].id
				}
			},
			// 选择优惠券
			chooseCoupon(){
				var arr = []
				arr.push(this.coupon_ids)
				if(Array.isArray(this.resId)){
					this.resId.toString()
				}
				this.$post('order/checkprice/',{
					goods_ids: this.resId.toString(),
					coupon_ids: arr,
					pt_coupon: this.pt_id,
					exchange_code: '1'
				}).then(response => {
					if (response.error == 0) {
						this.couponData = response.data;
						this.clickIn = true
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			
			// 下一步按钮
			toNext() {
				var arr = []
				arr.push(this.coupon_ids)
				if(Array.isArray(this.resId)){
					this.resId.toString()
				}
				if(this.$route.query.ismcro){
					this.$post('order/flow/',{
						goods_ids: this.resId,
						coupon_ids: arr,
						type: 1,
						is_micro:1,
						terminal:1,
						pay_type: this.choosePay,
						pt_coupon: this.pt_id,
						exchange_code: '1'
					}).then(response => {
						if (response.error == 0) {
							this.orderData = response.data
							this.$router.push({
								path: '/payWay',
								query:{
									data: this.orderData
								}
							})
						} else {
							this.$message.error(response.msg);
						}
					});
					return
				}
				this.$post('order/flow/',{
					goods_ids: this.resId,
					coupon_ids: arr,
					type: 1,
					terminal:1,
					pay_type: this.choosePay,
					pt_coupon: this.pt_id,
					exchange_code: '1'
				}).then(response => {
					if (response.error == 0) {
						this.orderData = response.data
						this.$router.push({
							path: '/payWay',
							query:{
								data: this.orderData
							}
						})
					} else {
						this.$message.error(response.msg);
					}
				});
				
			},
			// 选择支付方式
			toChoosePay() {
				this.choosePay = 1
			},
			toChoosePay1() {
				this.choosePay = 2
			},
			
		}
	};
</script>

<style scoped>
	.page-all {
		width: 70%;
		height: auto;
		margin: 0 auto;
		position: relative;
		top: 40px;
		margin-bottom: 52px;
		background-color: white;
		overflow: hidden;
		padding: 30px 20px;
	}

	.page-title {
		font-size: 16px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-bottom: 20px;
	}

	.page-content {
		width: 100%;
		height: auto;
		border: 1px solid #E6E6E6;
	}

	.contentTitle {
		width: 100%-20px;
		height: 46px;
		background: #F6F6F6;
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.contentText {
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-bottom: 22px;
		margin-left: 20px;
	}

	.organizeName1 {
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 31px;
		margin-left: 20px;
		margin-bottom: 15px;
	}

	.order {
		width: 100%;
		height: auto;
		background: #F6F6F6;
	}

	.orderItem {
		width: 100%;
		height: 136px;
		overflow: hidden;
	}

	.orderIcon {
		width: 144px;
		height: 96px;
		border-radius: 10px;
		background-color: #000000;
		float: left;
		margin-top: 19px;
		margin-left: 20px;
		margin-right: 15px;
	}

	.line {
		width: 100%;
		height: 1px;
		background: #CCCCCC;
	}

	.orderIntro {
		font-size: 16px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-top: 34px;
	}

	.orderPrice {
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-top: 15px;
	}

	.orderTotalBg {
		width: 100%;
		height: 65px;
		background: #E6E6E6;
		margin-bottom: 15px;
	}

	.orderTotal {
		float: right;
		margin-top: 15px;
		margin-right: 20px;
	}

	.orderTotal span:first-child {
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.orderTotal span:last-child {
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-left: 15px;
	}

	.wxPay {
		display: inline-block;
		
		width: 140px;
		height: 46px;
		border: 1px solid #E6E6E6;
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
		margin-bottom: 30px;
	}

	.wxPayIn {
		display: inline-block;
		width: 140px;
		height: 46px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
		margin-bottom: 30px;
	}

	.wxIcon {
		float: left;
		margin-left: 24px;
		margin-top: 10px;
		margin-right: 7px;
		width: 29px;
		height: 25px;
	}

	.okBg {
		position: absolute;
		margin-top: 22px;
		width: 25px;
		height: 25px;
		margin-left: 30px;
	}

	.okBg1 {
		position: absolute;
		margin-top: 22px;
		width: 25px;
		height: 25px;
		margin-left: 14px;
	}

	.zfbPay {
		display: inline-block;
		width: 140px;
		height: 46px;
		border: 1px solid #E6E6E6;
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
	}

	.zfbPayIn {
		display: inline-block;
		width: 140px;
		height: 46px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
	}

	.handleInput {
		width: 255px;
	}

	.exchangeBtn {
		width: 64px;
		height: 32px;
		background: #00D0B0;
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		position: absolute;
		margin-top: -32px;
		margin-left: 260px;
	}

	.contentForm {
		margin-left: 22px;
		margin-bottom: 15px;
	}

	.contentForm1 {
		margin-left: 33px;
		margin-bottom: 30px;
	}
	
	.page-total{
		width: 100%;
		height: 220px;
	}
	
	.totalItem{
		width: 170px;
		height: 30px;
		line-height: 30px;
		/* border: 1px solid red; */
	}
	
	.totalItem span:first-child{
		display: inline-block;
		width: 80px;
		height: 15px;
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		text-align: right;
	}
	
	.totalItem span:last-child{
		font-size: 14px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		float: right;
	}
	
	.submitBtn{
		width: 168px;
		height: 54px;
		background: #FB3752;
		font-size: 16px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		margin-top: 24px;

	}

	i {
		margin-top: 0;
	}
</style>
